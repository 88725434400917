import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { parse } from '../../util/urlHelpers';
import {
  Page,
  NamedLink,
  NamedRedirect,
  SocialLoginButton,
  Logo,
  Modal,
  TermsOfService,
} from '../../components';
import { ConfirmSignupForm, LoginForm } from '../../forms';
import { login, authenticationInProgress, signupWithIdp } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  updateProfile,
  uploadImage,
} from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import { FacebookLogo, GoogleLogo } from './socialLoginLogos';
import css from './LoginPage.module.css';

export class LoginPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
    };
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
  }

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      loginError,
      confirmError,
      scrollingDisabled,
      submitLogin,
      submitSingupWithIdp,
      tab,
      onManageDisableScrolling,
    } = this.props;

    const isConfirm = tab === 'confirm';
    const isLogin = tab === 'login';
    const locationFrom = location.state && location.state.from ? location.state.from : null;
    const authinfoFrom =
      this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;
    const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

    const fromPagePathWithoutSearch = from?.indexOf('?');
    const fromPagePath = from?.substring(0, fromPagePathWithoutSearch);
    const fromPageRoute = routeConfiguration().find(r => r.path === fromPagePath);

    const { recipientEmail: inviteEmail } = parse(from);
    const fillLoginFormEmailWithInviteEmail =
      fromPageRoute?.name === 'VerifyOrganizationInvitePage' ||
      fromPageRoute?.name === 'VerifyListingInvitePage'
        ? inviteEmail
        : null;

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    // Already authenticated, redirect away from auth page
    if (isAuthenticated && from) {
      return <Redirect to={from} />;
    } else if (isAuthenticated && currentUserLoaded) {
      return <NamedRedirect name="LandingPage" />;
    }

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupemailValidError(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    ) : null;

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const renderLoginError = errorMessage(loginError, loginErrorMessage);

    const fromState = { state: from ? { from } : null };

    const handleSubmitConfirm = values => {
      const { idpToken, email, firstName, lastName, idpId } = this.state.authInfo;
      const { email: newEmail, firstName: newFirstName, lastName: newLastName, ...rest } = values;

      // Pass email, fistName or lastName to Flex API only if user has edited them
      // sand they can't be fetched directly from idp provider (e.g. Facebook)

      const authParams = {
        ...(newEmail !== email && { email: newEmail }),
        ...(newFirstName !== firstName && { firstName: newFirstName }),
        ...(newLastName !== lastName && { lastName: newLastName }),
      };

      // If the confirm form has any additional values, pass them forward as user's protected data
      const protectedData = !isEmpty(rest) ? { ...rest } : null;

      submitSingupWithIdp({
        idpToken,
        idpId,
        ...authParams,
        ...(!!protectedData && { protectedData }),
      });
    };

    const getDefaultRoutes = () => {
      const routes = routeConfiguration();
      const baseUrl = apiBaseUrl();

      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      const fromParam = from ? `from=${from}` : '';

      // Default route where user is returned after successfull authentication
      const defaultReturn = pathByRouteName('LandingPage', routes);
      const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

      // Route for confirming user data before creating a new user
      const defaultConfirm = pathByRouteName('ConfirmLoginPage', routes);
      const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

      return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
    };
    const authWithFacebook = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const authWithGoogle = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const idp = this.state.authInfo
      ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
      : null;

    // Form for confirming information frm IdP (e.g. Facebook)
    // before new user is created to Flex
    const confirmForm = (
      <div className={css.content}>
        <h1 className={css.signupWithIdpTitle}>
          <FormattedMessage id="AuthenticationPage.confirmSignupWithIdpTitle" values={{ idp }} />
        </h1>
        <p className={css.confirmInfoText}>
          <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
        </p>
        {confirmErrorMessage}
        <ConfirmSignupForm
          className={css.form}
          onSubmit={handleSubmitConfirm}
          inProgress={authInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          authInfo={this.state.authInfo}
          idp={idp}
        />
      </div>
    );

    // Social login buttons
    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showSocialLogins = showFacebookLogin || showGoogleLogin;

    const facebookButtonText = <FormattedMessage id="AuthenticationPage.loginWithFacebook" />;
    const googleButtonText = <FormattedMessage id="AuthenticationPage.loginWithGoogle" />;

    const socialLoginButtonsMaybe = showSocialLogins ? (
      <div className={css.idpButtons}>
        <div className={css.socialButtonsOr}>
          <span className={css.socialButtonsOrText}>
            <FormattedMessage id="AuthenticationPage.or" />
          </span>
        </div>

        {showFacebookLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithFacebook()}>
              <span className={css.buttonIcon}>{FacebookLogo}</span>
              {facebookButtonText}
            </SocialLoginButton>
          </div>
        ) : null}

        {showGoogleLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithGoogle()}>
              <span className={css.buttonIcon}>{GoogleLogo}</span>
              {googleButtonText}
            </SocialLoginButton>
          </div>
        ) : null}
      </div>
    ) : null;

    const signupSeparator = (
      <div className={css.signupSeparator}>
        <p className={css.signupText}>
          <FormattedMessage
            id="AuthenticationPage.signupText"
            values={{
              signupLink: (
                <NamedLink className={css.signupLink} name="RoleSelectionPage">
                  <FormattedMessage id="AuthenticationPage.signupLink" />
                </NamedLink>
              ),
            }}
          />
        </p>
      </div>
    );

    // LoginForm
    const loginForm = (
      <div className={css.content}>
        <h1 className={css.contentTitle}>
          <FormattedMessage
            id="AuthenticationPage.loginTitle"
            values={{
              siteTitle: config.siteTitle,
            }}
          />
        </h1>

        {renderLoginError}
        {fillLoginFormEmailWithInviteEmail && isLogin ? (
          <div className={css.attentionBox}>
            <p className={css.attentionText}>
              <FormattedMessage
                id="AuthenticationPage.inviteLogin"
                values={{ email: <strong>{inviteEmail}</strong> }}
              />
            </p>
          </div>
        ) : null}
        <LoginForm
          className={css.loginForm}
          initialValues={{
            email: fillLoginFormEmailWithInviteEmail,
          }}
          onSubmit={submitLogin}
          inProgress={authInProgress}
        />

        {socialLoginButtonsMaybe}
        {signupSeparator}
      </div>
    );

    const formContent = isConfirm ? confirmForm : loginForm;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'AuthenticationPage.schemaTitleLogin' },
      { siteTitle }
    );

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <div className={css.root}>
          <div className={css.logoHeader}>
            <NamedLink className={css.logoLink} name="LandingPage">
              <Logo className={css.logo} showBrandIcon={false} />
            </NamedLink>
          </div>
          {formContent}
        </div>
        <Modal
          id="AuthenticationPage.tos"
          isOpen={this.state.tosModalOpen}
          onClose={() => this.setState({ tosModalOpen: false })}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.termsWrapper}>
            <h2 className={css.termsHeading}>
              <FormattedMessage id="AuthenticationPage.termsHeading" />
            </h2>
            <TermsOfService />
          </div>
        </Modal>
      </Page>
    );
  }
}

LoginPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  confirmError: null,
  showSocialLoginsForTests: false,
};

const { bool, func, object, oneOf, shape } = PropTypes;

LoginPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  tab: oneOf(['login', 'confirm']),

  onManageDisableScrolling: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, confirmError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LoginPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LoginPageComponent);

export default LoginPage;
